import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `https://staging-api.bookwineries.com/`,
});
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("wineryVendorToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Timezone"] = timeZone;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// /weret
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error?.response?.status === 401) {
      localStorage.removeItem("wineryVendorToken");
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
