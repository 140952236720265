import React from 'react';
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ value, onChange, error }) => {
  
  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code textcolor wordcount",
            "textarea",
            "textcolor",
            "forecolor backcolor",
          ],
          toolbar:
            "undo redo | formatselect | code |link | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
            "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
          content_style: "body { color: #000 }",
        }}
        onEditorChange={onChange}
        value={value}
      />
      {error && <div className="text-danger fs-12">{error}</div>}
    </>
  );
};

export default TextEditor;
